<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.categories')">
            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.ProductsWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('keys.categories.add_category') }}
                </b-button>
            </div>
            <Table v-if="arraysLoad" class="mt-1" :columnDefs="columnDefs" :rowData="productCategories" @deleteRow="remove" @editRow="editRow" @edit="edit"/>
        </b-card>
        <AddProductCategory ref="addModal" :vats="vats" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddProductCategory from '@/views/ProductCategory/AddProductCategory'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'

    export default {
        mixins: [itemChange],
        components: {Table, AddProductCategory, BCard, BOverlay, BButton},
        computed: {
            columnDefs() {
                const hasPermissions = this.$hasPermission(this.$permissions.ProductsWrite)

                const defsTmp = [
                    { headerName: this.$t('table_fields.name'), editable: hasPermissions, field: 'name', filter: true},
                    { headerName: this.$t('table_fields.vat'), editable: hasPermissions, field: 'vat_group_id', filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.vats} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.vats} }, cellRenderer: (params) => this.getVatName(params.value)},
                    { headerName: this.$t('table_fields.subcategories'), field: 'subcategory_ids', filter: true, editable: false, floatingFilterComponentParams: () => { return  {type: 'select', values: this.productSubcategories, multiple: true} }, cellRenderer: (params) => this.getCategoryName(params.value)}
                ]

                if (this.$hasPermission(this.$permissions.ProductsWrite)) {
                    defsTmp.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 150}
                    )
                }

                return defsTmp
            }
        },
        data() {
            return {
                productCategories: [],
                productSubcategories: [],
                vats: [],
                showLoader: false,
                arraysLoad: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = this.$http.get('/api/client/v1/product_categories/')
                    const response2 = this.$http.get('/api/client/v1/product_subcategories/')
                    const response3 = this.$http.get('/api/client/v1/vat_groups/')

                    const responses = await Promise.all([response1, response2, response3])
                    this.productCategories = responses[0].data ?? []
                    this.productSubcategories = responses[1].data ?? []
                    this.vats = responses[2].data ?? []
                    await this.itemChange()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/product_categories/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/product_categories/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getVatName(value) {
                if (value) {
                    const item = this.vats.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            getCategoryName(value) {
                if (value && value.length > 0) return value.filter(opt => {
                    const item = this.productSubcategories.find(x => x.id === opt)
                    return !!item
                }).map(option => {
                    const item = this.productSubcategories.find(x => x.id === option)
                    if (item) return item.name
                }).join(', ')
                return '/'
            }
        },
        async mounted() {
            await this.loadData()
            this.arraysLoad = true
        }
    }
</script>

<style scoped>

</style>
